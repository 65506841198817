@use '@angular/material' as mat;
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif'
);
// Create the theme
$theme: mat.m2-define-light-theme((
  typography: $custom-typography,
));


// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';
/*
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
*/
// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@include mat.all-component-themes($theme);

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.checkboxDagboek{
}

.mat-dialog-padding{
  padding: 20px; /* Adjust the padding as needed */
}
.klant-dialog{
  overflow-y:auto;
}
.mat-dialog-container {
  border-radius: 15px !important;
  max-width: 85vw !important;
  min-width: 880px !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 18px !important;
}

.nb-theme-default nb-sidebar.compacted {
  width: 3.5rem !important;
}

nb-sidebar{
  max-width: 250px !important;
  margin-right: 8px;
  .main-container{
    max-width: 250px !important;
  }
}

nb-select .placeholder{
  opacity:1 !important;
}

nb-menu .menu-item a {
  padding: clamp(4px, 0.7vh, 8px) clamp(16px, 1.5vh, 20px);
}

nb-menu .menu-group {
  padding: clamp(4px, 0.7vh, 8px) clamp(16px, 1.5vh, 20px);
}
.opslaan-spinner{
  background-color: white !important;
  width:170px;
  height:170px;
  --spinner-text-font-size:23px !important;
  --spinner-height-giant: 5rem;
  //poisiton center
  position: fixed !important;
  top: calc(50% - 90px) !important;
  left: calc(46% - 90px) !important;
  border-radius: 10px !important;
  box-shadow:  0 0 10px 5px rgba(0, 0, 0, 0.2);
  display:flex !important;
  flex-direction:column !important;
  justify-content:center !important;
  gap: 40px 0;
  align-items:center !important;
}
::ng-deep .bigPanelKopieerSelect div.mat-mdc-select-panel {
  font-size: 16px;
  max-height: 1000px; /* Adjust the maximum height of the panel */
  padding: 12px 0; /* Adjust the padding of the panel */
}
.bigPanelKopieerSelect {
  max-height: 1000px !important;
}

